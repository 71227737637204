<template>
<div>
<el-scrollbar
            style="height: 100%"
            class="scrollbar-for"
          >
<el-tag>所有订单统计起止时间</el-tag>
          <el-date-picker 
    v-model="minDate"
    type="datetime"
    :disabled="true">
  </el-date-picker>
          -
          <el-date-picker
    v-model="maxDate"
    type="datetime"
    :disabled="true">
    </el-date-picker>
<div class="conitem">

          <el-tag>所属项目</el-tag>
          <el-select
            class="select"
            v-loadmore="loadMore"
            filterable
            v-model="projectId"
            @change="projectChange"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              :key="index"
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in allprojects"
            >
            </el-option>
          </el-select>
        </div>
    <div class="option">
     <el-tag type="info">密令词（多密令词可用中文顿号分隔 例：1、2）</el-tag>
      <el-input v-model="secrets" placeholder="密令词" size="mini" style="width: 80rem; margin-right: 2rem"></el-input>
      <!--
      <el-select v-model="plus" clearable placeholder="是否为plus" size="mini" style="width: 8rem; margin-right: 2rem">
        <el-option label="全部" value="null"> </el-option>
        <el-option label="是" value="是"> </el-option>
        <el-option label="否" value="否"> </el-option>
      </el-select>
      -->
       <el-date-picker size="mini" v-model="dateRange" type="datetimerange" range-separator="至" start-placeholder="任务开始日期"
        end-placeholder="任务结束日期" value-format="yyyy-MM-dd HH:mm:ss" style="margin-right: 2rem">
      </el-date-picker>
      <el-button size="mini" type="primary" @click="reset">重置</el-button>
      <el-button size="mini" type="primary" @click="search()">搜索</el-button>
      <el-button size="mini" type="primary" @click="exportOrder()">导出</el-button>
    </div>
    
     <el-descriptions
      title="订单统计"
      :column="3"
      border
    >
      <el-descriptions-item
        v-for="item in detailDict"
        :key="item.key"
        :label="item.label"
      >
        {{ detailList[item.key] }}
      </el-descriptions-item>
    </el-descriptions>
<el-tag type="info">店铺排名</el-tag>
       <el-table :data="shopData" v-loading="loading" style="width: 100%"
    max-height="700" border >
    <el-table-column
        prop="shopName"
        label="店铺名">
      </el-table-column>
        <el-table-column
        prop="goodTotal"
        label="商品数">
      </el-table-column>
      </el-table>
      <el-tag type="info">商品排名</el-tag>
      <el-table :data="goodData" v-loading="loading" style="width: 100%"
    max-height="700" border >
    <el-table-column
        prop="skuid"
        label="skuid">
      </el-table-column>
        <el-table-column
        prop="goodName"
        label="商品名">
      </el-table-column>
      <el-table-column
        prop="goodTotal"
        label="商品数量总计">
      </el-table-column>
      </el-table>
       </el-scrollbar>
</div>

   
</template>

<script>
import { getJdOrder, exportJdOrder, getJdOrderStat,getList,getProjectSecrets,getRange } from "@/api/exportjdorder";
import router from "@/router";
import store from "@/store";
import { loadmore } from "@/config/directives";
export default {
  directives: {
    loadmore,
  },
  components: {},
  data() {
    return {
      // 选项栏
      secrets: '',
      dateRange: [],
      detailList: [],
      maxDate:null,
      minDate:null,
      plus: "",
      projectId:null,
       allprojects: [],
      tableData: [],
      shopData: [],
      goodData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      // 判断是否可以继续下拉数据
      alloadMore: true,
      detailDict: [
        { label: '有效订单量', key: 'orderNum'},
        { label: '预估计佣金额', key: 'planAmount' },
        { label: '实际计佣金额', key: 'realAmount'},
        { label: '预估佣金', key: 'planCommission'},
        { label: '实际佣金', key: 'realCommission'},
        { label: 'plus订单量', key: 'plusOrderNum'},
        { label: 'plus预估计佣金额', key: 'plusPlanAmount'},
        { label: 'plus实际计佣金额', key: 'plusRealAmount'},
        { label: 'plus预估佣金', key: 'plusPlanCommission'},
        { label: 'plus实际佣金', key: 'plusRealCommission'},
         { label: 'sku总数', key: 'skuidCount'},
          { label: '店铺总数', key: 'shopCount'},
      ]
    };
  },
  created() { },
  async  mounted(){
    await this.getList();
    await this.getRange();
    this.projectId = this.allprojects[0].id;
    this.getProjectSecrets();
    //await this.search();
    },
  watch: {},
  methods: {
    projectChange(val) {
      this.getProjectSecrets(val)
    },
     async getList() {
      await getList({ pageNo: this.pageNo, pageSize: 20 }).then((res) => {
        if (res.data.records.length > 0) {
          this.allprojects = [...this.allprojects, ...res.data.records];
          this.alloadMore = true;
        } else {
          this.alloadMore = false;
        }
      });
    },
    async getRange() {
      await getRange().then((res) => {
        if (res.code === 1000) {
          this.minDate = res.data.minDate;
          this.maxDate = res.data.maxDate;
        } else {
          this.alloadMore = false;
        }
      });
    },
     async getProjectSecrets() {
      await getProjectSecrets({ projectId: this.projectId }).then((res) => {
        if (res.code === 1000) {
            this.secrets = res.data;
          }else {
            this.$message({
            message: res.msg,
            type: "warning",
          });
      }
    })
    },
    // 加载更多
    loadMore() {
      if (this.alloadMore) {
        this.pageNo = this.pageNo + 1;
        this.getList();
      }
    },
    // 重置
    reset() {
      (this.secrets = ""), (this.plus = null), (this.dateRange = []);
    },
    async search(val) {
      this.loading = true;
      await getJdOrderStat({
                endAt: this.dateRange[1],
        secrets: this.secrets,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startAt: this.dateRange[0],
        plus: this.plus,
}
      ).then((res) => {
        this.loading = false;
  
        if (res.code == 1000)  {
          this.detailList = res.data,
          this.shopData = res.data.shopVos,
          this.goodData = res.data.goodVos
          this.$message({
            message: "搜索成功！",
            type: "success",
          });
        }
      });
    },
    async exportOrder(val) {
      this.loading = true;
      await exportJdOrder({
                endAt: this.dateRange[1],
        secrets: this.secrets,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startAt: this.dateRange[0],
        plus: this.plus,
}
      ).then((res) => {
        this.loading = false;
        if (res.code === 1000) {
            const link = document.createElement("a");
            link.href = res.data;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
