import request from '@/plugins/axios'
const base = '/'

export function exportJdOrder(data) {
  return request({
    url: base + 'api/jd/order/page/export',
    method: 'post',
    data
  })
}

export function getJdOrder(data) {
  return request({
    url: base + 'api/jd/order/page',
    method: 'post',
    data
  })
}

export function getJdOrderStat(data) {
  return request({
    url: base + 'api/jd/order/stat',
    method: 'post',
    data
  })
}

export function getList(data) {
  return request({
    url: base + 'api/zbProject/simple/all/page',
    method: 'post',
    data
  })
}

export function getRange(params) {
  return request({
    url: base + 'api/jd/order/range',
    method: 'get',
    params
  })
}

export function getProjectSecrets(params) {
  return request({
    url: base + 'api/zbProjectSecret/getByProject',
    method: 'get',
    params
  })
}